.scripting-ide {
    position: absolute;
    width: 100%;
    top: 55px;
    bottom: 0px;

    display: flex;
}

.scripting-panel {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.scripting-editor {
    overflow: hidden;
}

.scripting-console {
    display: flex;
    /* background-color: blue; */
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.212);
    align-items: stretch;
    flex-direction: column;

    max-height: 100%;
    overflow-y: auto;
}

.scripting-actions {
    margin-left: 10px;
    margin-right: 10px;

    /* background-color: orange; */
    border-bottom: 1px solid var(--primary-color);
}

.actions-row {
    display: flex;
    flex-direction: row;
}

.actions-row p {
    margin-right: 10px;
}