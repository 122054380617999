.avatar img,
img.avatar {
  border-radius: 100px;
}

.page-wrapper {
  margin: 10px;
}

code {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  color: rgb(247, 85, 85);
}

a {
  text-decoration: none;
  color: white;
}

.bl-button {
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  background: none;
  color: white;
  border: none !important;
  padding: 1px 10px;
  font-size: 1em;
  border-radius: 4px;
  background-color: var(--primary-color);
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  min-height: 35px;
}

.bl-button:hover {
  background-color: var(--primary-color-dark);
}

.bl-button:disabled {
  background-color: black;
  cursor: not-allowed;
}

.bl-button.danger {
  background-color: var(--danger-color);
}

.bl-button.danger:hover {
  background-color: var(--danger-color-dark);
}