.panel {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.panel-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
}

.panel h1,
.panel h2,
.panel h3 {
    border-bottom: 2px solid var(--main-bg-color);
}
